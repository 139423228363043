export default class DownloadEventsResultsRequestDTO {
    constructor(dateDiapasone, regionalStatusId, resultsType, eventCategoryId, eventDisciplineId, stage, placesDiapasone) {
        this.dateDiapasone = dateDiapasone;
        this.regionalStatusId = regionalStatusId;
        this.resultsType = resultsType;
        this.eventCategoryId = eventCategoryId;
        this.eventDisciplineId = eventDisciplineId;
        this.stage = stage;
        this.placesDiapasone = placesDiapasone;
    }
    static fromObject(obj) {
        return new DownloadEventsResultsRequestDTO(obj.dateDiapasone, obj.regionalStatusId, obj.resultsType, obj.eventCategoryId, obj.eventDisciplineId, obj.stage, obj.placesDiapasone);
    }
}
