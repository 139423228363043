import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
let NumberRangePicker = class NumberRangePicker extends Vue {
    constructor() {
        super(...arguments);
        this.from = {
            ref: "from",
            type: "input",
            label: "С",
            value: "",
            showOnlyForSuperAuth: false,
            inputType: "number",
            rules: [],
            onInput: () => {
                this.$emit("range-update", {
                    from: isNaN(parseInt(this.from.value, 10)) ? null : parseInt(this.from.value, 10),
                    to: isNaN(parseInt(this.to.value, 10)) ? null : parseInt(this.to.value, 10),
                });
            },
        };
        this.to = {
            ref: "to",
            type: "input",
            label: "По",
            value: "",
            inputType: "number",
            isOpened: false,
            showOnlyForSuperAuth: false,
            rules: [],
            onInput: () => {
                this.$emit("range-update", {
                    from: isNaN(parseInt(this.from.value, 10)) ? null : parseInt(this.from.value, 10),
                    to: isNaN(parseInt(this.to.value, 10)) ? null : parseInt(this.to.value, 10),
                });
            },
        };
        this.fields = [this.from, this.to];
    }
    onDropChange() {
        this.from.value = null;
        this.to.value = null;
    }
};
__decorate([
    Prop({ type: String, required: true })
], NumberRangePicker.prototype, "title", void 0);
__decorate([
    Prop({ type: Boolean })
], NumberRangePicker.prototype, "drop", void 0);
__decorate([
    Watch("drop")
], NumberRangePicker.prototype, "onDropChange", null);
NumberRangePicker = __decorate([
    Component({})
], NumberRangePicker);
export default NumberRangePicker;
