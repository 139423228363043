import { __decorate } from "tslib";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
let DateRangePicker = class DateRangePicker extends Vue {
    constructor() {
        super(...arguments);
        this.from = {
            ref: "from",
            type: "datepicker",
            label: "С даты",
            value: moment().startOf("year").format("YYYY-MM-DD"),
            isOpened: false,
            showOnlyForSuperAuth: false,
            rules: [],
            onInput: () => {
                this.from.isOpened = !this.from.isOpened;
                this.$emit("range-update", {
                    from: this.from.value,
                    to: this.to.value,
                });
            },
        };
        this.to = {
            ref: "to",
            type: "datepicker",
            label: "По дату",
            value: moment().endOf("year").format("YYYY-MM-DD"),
            isOpened: false,
            showOnlyForSuperAuth: false,
            rules: [],
            onInput: () => {
                this.from.isOpened = !this.from.isOpened;
                this.$emit("range-update", {
                    from: this.from.value,
                    to: this.to.value,
                });
            },
        };
        this.fields = [this.from, this.to];
    }
};
__decorate([
    Prop({ type: String, required: true })
], DateRangePicker.prototype, "title", void 0);
DateRangePicker = __decorate([
    Component({})
], DateRangePicker);
export default DateRangePicker;
