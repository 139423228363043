import { __decorate } from "tslib";
import DateRangePicker from "@/components/shared/DateRangePicker.vue";
import NumberRangePicker from "@/components/shared/NumberRangePicker.vue";
import DownloadEventsResultsRequestDTO from "@/shared/application/DownloadEventsResultsRequestDTO";
import EventModule from "@/store/module/shared/EventModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
let EventsResultsDownloadDialog = class EventsResultsDownloadDialog extends Vue {
    constructor() {
        super(...arguments);
        this.dateDiapasone = {
            from: moment().startOf("year").format("YYYY-MM-DD"),
            to: moment().endOf("year").format("YYYY-MM-DD"),
        };
        this.regionalStatus = {
            ref: "regionalStatus",
            type: "select",
            title: "Региональный статус",
            label: "Выберите региональный статус",
            value: null,
            items: () => this.eventRegionalStatuses,
            onInput: (id) => { },
            isMultiple: false,
            noData: "Список региональных статусов пуст",
            showOnlyForSuperAuth: false,
            disabled: false,
            rules: [],
            show: true,
        };
        this.resultsType = {
            ref: "resultsType",
            type: "select",
            title: "Тип результатов",
            label: "Выберите тип результатов",
            value: null,
            items: () => [
                { label: "-", value: null },
                { label: "Индивидуальные", value: "individual" },
                { label: "Командные", value: "team" },
            ],
            onInput: () => { },
            isMultiple: false,
            noData: "",
            showOnlyForSuperAuth: false,
            disabled: false,
            rules: [],
            show: true,
        };
        this.category = {
            ref: "category",
            type: "select",
            title: "Категория",
            label: "Выберите категорию",
            value: null,
            items: () => this.eventCategories,
            onInput: (id) => { },
            isMultiple: false,
            noData: "Список категорий пуст",
            showOnlyForSuperAuth: false,
            disabled: false,
            rules: [],
            show: true,
        };
        this.discipline = {
            ref: "discipline",
            type: "select",
            title: "Дисциплина",
            label: "Выберите дисциплину",
            value: null,
            items: () => this.disciplinesList,
            onInput: (id) => { },
            isMultiple: false,
            noData: "Список дисциплин пуст",
            showOnlyForSuperAuth: false,
            disabled: false,
            rules: [],
            show: true,
        };
        this.stage = {
            ref: "stage",
            type: "select",
            title: "Этап",
            label: "Выберите этап",
            value: null,
            items: () => [
                { label: "-", value: null },
                { label: "Квалификация", value: "qualification" },
                { label: "Финал", value: "final" },
            ],
            onInput: (id) => { },
            isMultiple: false,
            noData: "",
            showOnlyForSuperAuth: false,
            disabled: false,
            rules: [],
            show: true,
        };
        this.fields = [this.regionalStatus, this.resultsType, this.category, this.discipline, this.stage];
        this.placeDiapasone = {
            from: null,
            to: null,
        };
        this.dropPlaceFilter = false;
    }
    get showDialog() {
        return this.value;
    }
    set showDialog(value) {
        this.$emit("input", value);
    }
    get eventRegionalStatuses() {
        const items = this.$store.getters[HandbookModule.types.getters.EVENT_REGIONAL_STATUSES].map((e) => ({
            label: e.name,
            value: e.id,
        }));
        items.unshift({ label: "-", value: null });
        return items;
    }
    get eventCategories() {
        const items = this.$store.getters[HandbookModule.types.getters.EVENT_CATEGORIES].map((e) => ({
            label: e.name,
            value: e.id,
        }));
        items.unshift({ label: "-", value: null });
        return items;
    }
    get disciplinesList() {
        const disciplineGroups = this.$store.getters[HandbookModule.types.getters.EVENT_DISCIPLINES_GROUPS];
        const dGroupsMap = new Map(disciplineGroups.map((i) => [i.id, i]));
        const disciplines = this.$store.getters[HandbookModule.types.getters.EVENT_DISCIPLINES];
        const items = disciplines.map((i) => ({
            label: `${dGroupsMap.get(i.groupId).name} | ${i.name}`,
            value: i.id,
        }));
        items.unshift({ label: "-", value: null });
        return items;
    }
    onDateRangeUpdate(data) {
        this.dateDiapasone.from = data.from;
        this.dateDiapasone.to = data.to;
    }
    dropFilters() {
        for (const field of this.fields) {
            field.value = null;
            this.placeDiapasone.from = null;
            this.placeDiapasone.to = null;
            this.dropPlaceFilter = !this.dropPlaceFilter;
        }
    }
    onPlaceRangeUpdate(data) {
        console.log(data);
        this.placeDiapasone.from = data.from;
        this.placeDiapasone.to = data.to;
    }
    async download() {
        if (moment(this.dateDiapasone.from).isAfter(this.dateDiapasone.to)) {
            return this.$notify({
                type: "warning",
                title: "Неверно указан диапазон дат",
            });
        }
        if (moment(this.placeDiapasone.from).isAfter(this.placeDiapasone.to)) {
            return this.$notify({
                type: "warning",
                title: "Неверно указан диапазон мест",
            });
        }
        await this.$store.dispatch(EventModule.types.actions.DOWNLOAD_RESULTS_REPORT, new DownloadEventsResultsRequestDTO(this.dateDiapasone, this.regionalStatus.value && !isNaN(parseInt(String(this.regionalStatus.value), 10))
            ? parseInt(String(this.regionalStatus.value), 10)
            : null, this.resultsType.value ? String(this.resultsType.value) : null, this.category.value && !isNaN(parseInt(String(this.category.value), 10))
            ? parseInt(String(this.category.value), 10)
            : null, this.discipline.value && !isNaN(parseInt(String(this.discipline.value), 10))
            ? parseInt(String(this.discipline.value), 10)
            : null, this.stage.value ? String(this.stage.value) : null, this.placeDiapasone));
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], EventsResultsDownloadDialog.prototype, "value", void 0);
EventsResultsDownloadDialog = __decorate([
    Component({
        components: {
            DateRangePicker,
            NumberRangePicker,
        },
    })
], EventsResultsDownloadDialog);
export default EventsResultsDownloadDialog;
